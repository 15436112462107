/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/*FONTS */
@font-face {
  font-family: rajdhani-bold;
  src: url(./assets/fuentes/rajdhani-bold.ttf) format("truetype");
}

@font-face {
  font-family: rajdhani-light;
  src: url(./assets/fuentes/rajdhani-light.ttf) format("truetype");
}

@font-face {
  font-family: rajdhani-medium;
  src: url(./assets/fuentes/rajdhani-medium.ttf) format("truetype");
}

@font-face {
  font-family: rajdhani-regular;
  src: url(./assets/fuentes/rajdhani-regular.ttf) format("truetype");
}

@font-face {
  font-family: rajdhani-semibold;
  src: url(./assets/fuentes/rajdhani-semibold.ttf) format("truetype");
}


/*COLORS */
$color-principal: #177805;
$color-background: #0f5702;
$color-secondary: #f49c06;
$color-title: #f49c06;
$color-font: rgba(255,255,255,0.85);
$color-icon-primary: #59d63a;

/*FONTS THEME*/
$font-bold: rajdhani-bold;
$font-semibold: rajdhani-semibold;
$font-medium: rajdhani-medium;
$font-regular: rajdhani-regular;
$font-light: rajdhani-light;
